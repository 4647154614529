import { Injectable } from "@angular/core";
import {
  HttpInterceptor,
  HttpRequest,
  HttpHandler,
  HttpEvent,
} from "@angular/common/http";

import { TokenStorageService } from "./token-storage.service";
import { Observable } from "rxjs";

const TOKEN_HEADER_KEY = "Authorization";
const ORIGIN_HEADER_KEY = "X-Origin";

@Injectable({
  providedIn: "root",
})
export class AuthInterceptor implements HttpInterceptor {
  constructor(private tokenStorageService: TokenStorageService) {}

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    let authReq = req;
    // add token
    const token = this.tokenStorageService.getToken();
    if (token != null) {
      authReq = req.clone({
        headers: req.headers.set(TOKEN_HEADER_KEY, "Bearer " + token),
      });
    }
    // add origin
    // const origin = this.tokenStorageService.getOrigin();
    // if (origin != null) {
    //   authReq = req.clone({ headers: req.headers.set(ORIGIN_HEADER_KEY, origin) });
    // }

    return next.handle(authReq);
  }
}
