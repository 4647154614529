import { MediaMatcher } from "@angular/cdk/layout";
import {
  ChangeDetectorRef,
  Component,
  ViewChild,
  AfterViewInit,
  OnInit,
  OnDestroy,
} from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { MatSidenav, MatSidenavContainer } from "@angular/material/sidenav";
import { Subscription } from "rxjs";

import { environment } from "../environments/environment";
import { User } from "./user/user";
import { AuthenticationService } from "./shared/services/authentication.service";
import { TokenStorageService } from "./shared/services/token-storage.service";
import { TranslateService } from "@ngx-translate/core";
import { LanguageService } from "./shared/services/language.service";
@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.css"],
})
export class AppComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild(MatSidenavContainer) sidenavContainer: MatSidenavContainer;
  @ViewChild("sidenav") sidenav: MatSidenav;

  fillerNav = Array.from({ length: 5 }, (_, i) => `Nav Item ${i + 1}`);

  mobileQuery: MediaQueryList;
  title = "PDSS";
  host = environment.host;

  authUser: User;
  authUserId: number = 0;

  authUserGetSub$: Subscription;
  authUserSignOutSub$: Subscription;

  languages: LanguageItem[] = [
    {
      code: "ko",
      label: "한국어",
    },
    { code: "en", label: "English" },
  ];
  selectedLanguage: LanguageItem = this.languages[0];

  // subscription
  private $subscription: Subscription = new Subscription();

  private _mobileQueryListener: () => void;

  constructor(
    changeDetectorRef: ChangeDetectorRef,
    media: MediaMatcher,
    private authenticationService: AuthenticationService,
    private tokenStorageService: TokenStorageService,
    private router: Router,
    private translateService: TranslateService,
    private activatedRoute: ActivatedRoute,
    private languageService: LanguageService
  ) {
    this.mobileQuery = media.matchMedia("(max-width: 600px)");
    this._mobileQueryListener = () => changeDetectorRef.detectChanges();

    this.mobileQuery.addEventListener("change", (e) => {
      if (e.matches) {
        /* the viewport is 600 pixels wide or less */
        // console.log('Narrow screen — less than 600px wide');
      } else {
        /* the viewport is more than than 600 pixels wide */
        // console.log('Wide screen — more than 600px wide');
      }
    });
  }

  ngOnInit(): void {
    this.subscribeQueryParamsLanguage();
    this.authUserGetSub$ =
      this.authenticationService.authUserObservable$.subscribe((next: User) => {
        if (!!next && !!next.id) {
          this.authUser = next;
          this.authUserId = next.id;

          // console.log('[AppComponent.ngOnInit()] signedInUser=' + JSON.stringify(next));
        } else {
          this.authUser = null;
          this.authUserId = 0;
        }
      });

    this.authUserId = this.tokenStorageService.getAuthUserId();
    // console.log('[AppComponent.ngOnInit()] this.authUserId=' + this.authUserId);
  }

  /**
   * AfterViewInit hook
   */
  ngAfterViewInit(): void {
    this.sidenavContainer.scrollable
      .elementScrolled()
      .subscribe(() => this.scroll());
  }

  /**
   * Destroy hook
   */
  ngOnDestroy(): void {
    // this.mobileQuery.removeListener(this._mobileQueryListener);
    this.mobileQuery.removeEventListener("change", () => {});
    if (!!this.authUserGetSub$) {
      this.authUserGetSub$.unsubscribe();
    }
    this.$subscription.unsubscribe();
  }

  /**
   * subscribe query params language
   */
  subscribeQueryParamsLanguage(): void {
    const queryParamsSubscription = this.activatedRoute.queryParams.subscribe(
      (params) => {
        const language: string = params.lang;
        const languageFromStorage: string = this.languageService.getLang();
        // if have language in query params, use language from query params and save to storage
        if (!!language) {
          this.selectedLanguage =
            this.languages.find((item) => item.code === language) ||
            this.languages[0];
          this.translateService.use(this.selectedLanguage.code);
          this.languageService.saveLang(this.selectedLanguage.code);
        }

        // If don't have language in query params, use language from storage
        if (!language) {
          if (languageFromStorage) {
            this.translateService.use(languageFromStorage);
          } else {
            this.translateService.use(this.selectedLanguage.code);
          }
        }
      }
    );
    this.$subscription.add(queryParamsSubscription);
  }
  /**
   * sync selected language to query params in case of language change
   */
  syncLanguageToQueryParams(): void {
    this.router.navigate([], {
      relativeTo: this.activatedRoute,
      queryParams: { lang: this.selectedLanguage.code },
      queryParamsHandling: "merge",
    });
  }

  /**
   * Close sidenav
   */
  close(): void {
    this.sidenav.close();
  }

  /**
   * Scrolling
   */
  scroll(): void {}

  navigateQrCodeScanner(): void {
    this.router.navigate(["report"]);
  }

  navigateSignInOut(): void {
    // console.log('[AppComponent.navigateSignInOut()] authenticationService.authUserId=' + this.authUserId);
    if (this.authUserId > 0) {
      // sign-out
      this.authUserSignOutSub$ = this.authenticationService
        .signOut()
        .subscribe({
          next: (response) => {
            this.tokenStorageService.removeToken();
            this.authenticationService.setAuthUser(null);
          },
          error: (error) => {},
        });
    }

    // redirect to sign-in
    this.router.navigate(["user", 0, "signin"]);
  }

  navigateUser(): void {
    // console.log('[AppComponent.navigateUser()] authUserId=' + this.authUserId);
    this.router.navigate(["user", this.authUserId, "detail"]);
  }

  navigateReport(): void {
    // console.log('[AppComponent.navigateReport()] authUserId=' + this.authUserId);
    this.router.navigate(["user", this.authUserId, "report"]);
  }

  navigateGene(): void {
    // console.log('[AppComponent.navigateGene()] authUserId=' + this.authUserId);
    this.router.navigate(["user", this.authUserId, "gene"]);
  }

  // changeLanguage from matSelect
  changeLanguage(language: LanguageItem): void {
    this.selectedLanguage = language;
    this.translateService.use(language.code);
    this.languageService.saveLang(language.code);
    this.syncLanguageToQueryParams();
  }
}

interface LanguageItem {
  code: string;
  label: string;
}
