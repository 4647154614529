<br />
<span class="risk-level-explain-label"></span><br />
<div class="risk-level-explain">
  <div class="good"></div>
  <span class="risk-level-text">{{ RiskLevelEnumKr.GOOD }}&nbsp;</span>

  <div class="caution"></div>
  <span class="risk-level-text">{{ RiskLevelEnumKr.CAUTION }}&nbsp;</span>

  <div class="warning"></div>
  <span class="risk-level-text">{{ RiskLevelEnumKr.WARNING }}&nbsp;</span>

  <div class="danger"></div>
  <span class="risk-level-text-no-padding"
    >{{ RiskLevelEnumKr.DANGER }}&nbsp;</span
  >

  <div class="unknown"></div>
  <span class="risk-level-text">{{ RiskLevelEnumKr.UNKNOWN }}&nbsp;</span>

  <mat-icon (click)="onGetRiskLevelExplanation()">open_in_new</mat-icon><br />

  <span id="green-percent">{{ riskPercentage.good }}%</span>
  <span id="yellow-percent">{{ riskPercentage.caution }}%</span>
  <span id="orange-percent">{{ riskPercentage.warning }}%</span>
  <span id="red-percent">{{ riskPercentage.danger }}%</span>
  <span id="gray-percent">{{ riskPercentage.unknown }}%</span>
</div>
<br />
