<div>
  <a
    aria-label="Gene"
    [routerLink]="['/report', qrCode, 'gene', variant.userGeneSymbol, 'detail']"
    title="Gene"
    >{{ variant.userGeneSymbol }}</a
  >
</div>

<div class="gray-div">
  <span class="span-header">
    {{ "REPORT_GENE_INFO__GENOTYPE" | translate }}
  </span>
  <span>{{
    "REPORT_GENE_INFO__GENOTYPE_DETAIL"
      | translate
        : {
            genotypePercentage: genotypePercentage,
            genotypeFrequencyStudy: genotypeFrequencyStudy
          }
  }}</span>
</div>

<div *ngIf="phenotypeDetail" class="gray-div">
  <span class="span-header">
    {{ "REPORT_GENE_INFO__PHENOTYPE" | translate }}
  </span>
  <span>{{ phenotypeDetail }}</span>
</div>

<div class="gray-div">
  <span class="span-header">
    {{ "REPORT_GENE_INFO__PHENOTYPE_ANNOTATION" | translate }}
  </span>
  <app-select-lang>
    <span forEn>{{ variant?.variantPhenotypeSummary }}</span>
    <span forKo>{{ variant?.kr?.variantPhenotypeSummary }}</span>
  </app-select-lang>
</div>

<div class="gray-div">
  <span class="span-header">
    {{ "REPORT_GENE_INFO__RELATED_DRUGS" | translate }}
  </span>
  <app-select-lang>
    <span forEn>{{ variant?.relatedDrugs }}</span>
    <span forKo>{{ variant?.kr?.relatedDrugs }}</span>
  </app-select-lang>
</div>
