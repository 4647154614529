import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";

const LANG_KEY = "lang_key";

@Injectable({
  providedIn: "root",
})
export class LanguageService {
  private lang$ = new BehaviorSubject<string>(null);

  constructor() {
    this.lang$.next(this.getLang());
  }

  public saveLang(lang: string): void {
    window.localStorage.removeItem(LANG_KEY);
    window.localStorage.setItem(LANG_KEY, lang);
    this.lang$.next(lang);
  }

  public getLang(): string {
    return localStorage.getItem(LANG_KEY);
  }

  public subscribeLang(): BehaviorSubject<string> {
    return this.lang$;
  }
}
