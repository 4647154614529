<div>
  <app-risk-level-icon [risk]="drugRecommendation.risk"></app-risk-level-icon>
  <!-- <span>{{ drugRecommendation.drugName }}</span> -->
  <a
    aria-label="Drug"
    [routerLink]="[
      '/report',
      qrCode,
      'drug',
      drugRecommendation.drugName,
      'detail'
    ]"
    title="Drug"
    >{{ drugRecommendation.drugName }}</a
  >
</div>

<div class="gray-div">
  <!-- <span class="span-header"> Genetic Testing Result and Interpretation:</span> -->
  <span class="span-header">
    {{
      "DRUG_RECOMMENDATION__GENETIC_TESTING_RESULT_AND_INTERPRETATION"
        | translate
    }}
  </span>
  <!-- <span>{{ drugRecommendation.relatedGenes}}</span> -->
  <app-drug-recommendation-gene
    *ngFor="let gene of drugRecommendation.genes"
    [gene]="gene"
    [totalInterpretationDisplay]="drugRecommendation.totalInterpretationDisplay"
  ></app-drug-recommendation-gene>
</div>

<!-- <div class="gray-div">
    <span class="span-header">Related Diseases:</span>
    <span>{{ drugRecommendation.relatedDiseases }}</span>
</div> -->

<!-- [innerHTML] -->
<div class="gray-div" *ngIf="!!drugRecommendation.recommendation">
  <span class="span-header">
    {{ "DRUG_RECOMMENDATION__INTERPRETATION" | translate }}
  </span>
  <app-select-lang>
    <span forEn>{{ drugRecommendation?.recommendation }}</span>
    <span forKo>{{ drugRecommendation?.kr?.recommendation }}</span>
  </app-select-lang>
</div>

<div class="gray-div" *ngIf="!!drugRecommendation.dose">
  <span class="span-header">
    {{ "DRUG_RECOMMENDATION__PREDICTED_DOSE" | translate }}
  </span>
  <app-select-lang>
    <span forEn>{{ drugRecommendation?.dose }}</span>
    <span forKo>{{ drugRecommendation?.kr?.dose + " (mg/day)" }}</span>
  </app-select-lang>
</div>
