import {
  AfterViewInit,
  Directive,
  ElementRef,
  Input,
  OnDestroy,
  OnInit,
} from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { Subscription } from "rxjs";

@Directive({
  selector: "[appSelectLang]",
})
export class SelectLangDirective implements OnInit, AfterViewInit, OnDestroy {
  subscription$ = new Subscription();

  en: any;
  ko: any;

  @Input() set appSelectLang({ en = "", ko = "" }: { en: any; ko: any }) {
    this.en = en;
    this.ko = ko;
  }
  constructor(
    private translateService: TranslateService,
    private el: ElementRef
  ) {}

  ngOnInit(): void {
    this.subscribeToLangChange();
    this.initValue();
  }

  initValue(): void {
    this.el.nativeElement.innerHTML =
      this.translateService.currentLang === "ko" ? this.ko : this.en;
  }

  ngAfterViewInit(): void {}

  subscribeToLangChange(): void {
    this.subscription$.add(
      this.translateService.onLangChange.subscribe((lang) => {
        if (lang.lang === "ko") {
          this.el.nativeElement.innerHTML = this.ko;
        } else {
          this.el.nativeElement.innerHTML = this.en;
        }
      })
    );
  }
  ngOnDestroy(): void {
    this.subscription$.unsubscribe();
  }
}
