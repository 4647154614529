import { Component, OnInit, OnDestroy } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { MatDialog } from "@angular/material/dialog";

import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";

import { ReportDataService } from "../../../report/report-data.service";
import { UserDataService } from "../../../user/user-data.service";

import { RiskLevelExplainComponent } from "../risk-level-explain/risk-level-explain.component";

import { Report } from "../../../report/report";
import {
  RiskLevelEnumCapitalized,
  RiskLevelEnumKr,
} from "../risk-level-icon/risk-level-enum";

export class RiskPercentage {
  good: number;
  caution: number;
  warning: number;
  danger: number;
  unknown: number;
}

@Component({
  selector: "app-risk-level-display",
  templateUrl: "./risk-level-display.component.html",
  styleUrls: ["./risk-level-display.component.css"],
})
export class RiskLevelDisplayComponent implements OnInit, OnDestroy {
  private readonly destroy$ = new Subject();
  reports: Report[] | null;
  total: number;
  riskPercentage: RiskPercentage = new RiskPercentage();
  authUserId: number = 0;
  RiskLevelEnumKr = RiskLevelEnumKr;

  constructor(
    private route: ActivatedRoute,
    private reportDataService: ReportDataService,
    private userDataService: UserDataService,
    private dialog: MatDialog
  ) {}

  ngOnInit(): void {
    this.authUserId = this.route.snapshot.params.id;
    this.getReports();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  onGetRiskLevelExplanation(): void {
    const width = "auto";
    const height = "auto";

    const dialogRef = this.dialog.open(RiskLevelExplainComponent, {
      width,
      height,
    });
  }

  aggreateRisk(): void {
    // console.log(this.reports[0].drugRecommendations.length);
    let green: number = 0;
    let yellow: number = 0;
    let orange: number = 0;
    let red: number = 0;
    let gray: number = 0;
    const risk: string = "risk";

    this.reports[0].drugRecommendations.forEach((rec) => {
      switch (rec[risk]) {
        case RiskLevelEnumCapitalized.GOOD:
          green++;
          break;
        case RiskLevelEnumCapitalized.CAUTION:
          yellow++;
          break;
        case RiskLevelEnumCapitalized.WARNING:
          orange++;
          break;
        case RiskLevelEnumCapitalized.DANGER:
          red++;
          break;
        case RiskLevelEnumCapitalized.UNKNOWN:
          gray++;
          break;
      }
    });

    this.total = green + yellow + orange + red;
    this.riskPercentage.good = Math.round((green / this.total) * 100);
    this.riskPercentage.caution = Math.round((yellow / this.total) * 100);
    this.riskPercentage.warning = Math.round((orange / this.total) * 100);
    this.riskPercentage.danger = Math.round((red / this.total) * 100);
    this.riskPercentage.unknown = Math.round((gray / this.total) * 100);

    // console.log("[risk-level-display-components.aggreateRisk()] green:" + this.riskPercentage.good);
    // console.log("yellow:" + (yellow/this.total)*100 + "%");
    // console.log("orange:" + (orange/this.total)*100 + "%");
    // console.log("red:" + (red/this.total)*100 + "%");
  }

  getReports(): void {
    // this.reportsGetSub$ = this.reportDataService.getAll()
    this.userDataService
      .getAllReportById(this.authUserId)
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: (response) => {
          if (!!response.data && !!response.data.items) {
            this.onGetReport(response.data.items);
          }
        },
        error: (error) => {
          console.log(error);
        },
      });
  }

  /**
   * Call back for getting all reports
   */
  onGetReport(reports: Report[]): void {
    this.reports = reports;
    // show percentage for each risk-color
    this.aggreateRisk();
  }
}
