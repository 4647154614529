import { Component, OnInit } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { Subscription } from "rxjs";

@Component({
  selector: "app-select-lang",
  templateUrl: "./select-lang.component.html",
  styleUrls: ["./select-lang.component.css"],
})
export class SelectLangComponent implements OnInit {
  lang: string;
  private subscription$ = new Subscription();

  constructor(private translateService: TranslateService) {}

  ngOnInit(): void {
    this.subscribeToLangChange();
    this.initValue();
  }

  subscribeToLangChange(): void {
    this.subscription$.add(
      this.translateService.onLangChange.subscribe((lang) => {
        this.lang = lang.lang;
      })
    );
  }

  initValue(): void {
    this.lang = this.translateService.currentLang;
  }
}
