import { Component, Input, OnInit } from "@angular/core";
import {
  RiskLevelEnumCapitalized,
  RiskLevelEnum,
  RiskLevelEnumKr,
} from "./risk-level-enum";

@Component({
  selector: "app-risk-level-icon",
  templateUrl: "./risk-level-icon.component.html",
  styleUrls: ["./risk-level-icon.component.css"],
})
export class RiskLevelIconComponent implements OnInit {
  @Input() risk: string;

  cssClass: string;
  show: boolean = true;

  constructor() {}

  ngOnInit(): void {
    if (
      this.risk === RiskLevelEnumKr.GOOD ||
      this.risk === RiskLevelEnumCapitalized.GOOD
    ) {
      this.cssClass = RiskLevelEnum.GOOD;
    } else if (
      this.risk === RiskLevelEnumKr.CAUTION ||
      this.risk === RiskLevelEnumCapitalized.CAUTION
    ) {
      this.cssClass = RiskLevelEnum.CAUTION;
    } else if (
      this.risk === RiskLevelEnumKr.WARNING ||
      this.risk === RiskLevelEnumCapitalized.WARNING
    ) {
      this.cssClass = RiskLevelEnum.WARNING;
    } else if (
      this.risk === RiskLevelEnumKr.DANGER ||
      this.risk === RiskLevelEnumCapitalized.DANGER
    ) {
      this.cssClass = RiskLevelEnum.DANGER;
    } else if (
      this.risk === RiskLevelEnumKr.UNKNOWN ||
      this.risk === RiskLevelEnumCapitalized.UNKNOWN
    ) {
      this.cssClass = RiskLevelEnum.UNKNOWN;
    } else {
      this.show = false;
    }
  }
}
