<br />
<a
  aria-label="Drug"
  [routerLink]="['/report', qrCode, 'gene', gene.symbol, 'detail']"
  title="Gene"
  ><strong>{{ "  " + gene.symbol + " " }}</strong></a
>

<app-select-lang>
  <span forEn>
    {{ phenotypeDetail }}
    <ng-container *ngIf="!totalInterpretationDisplay">{{ gene?.recommendation || gene?.kr?.recommendation }}</ng-container>
  </span>
  <span forKo>{{ phenotypeDetail }} 
    <ng-container *ngIf="!totalInterpretationDisplay">{{ gene?.kr?.recommendation }}</ng-container>
  </span>
</app-select-lang>
