import { Component, Inject, OnInit } from "@angular/core";
import { MatDialog, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { marker } from "@biesbjerg/ngx-translate-extract-marker";

@Component({
  selector: "app-risk-level-explain",
  templateUrl: "./risk-level-explain.component.html",
  styleUrls: ["./risk-level-explain.component.css"],
})
export class RiskLevelExplainComponent implements OnInit {
  displayedColumns: string[] = ["label", "expectation"];
  dataSource: RiskLevel[] = [];

  constructor(
    private dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA)
    public dialogInputData: InputData
  ) {
    this.dataSource = [...ELEMENT_DATA];
    if (dialogInputData?.isHaveUnknown) {
      this.dataSource.push({
        label: "<img src='../assets/pcdss/manual/risk-color-gray.png'/>",
        // expectation: "아직 연구가 부족한 약물입니다 (일반 처방을 따라주세요)",
        expectation: marker("RISK_LEVEL_EXPLAIN__UNKNOWN"),
      });
    }
  }

  ngOnInit(): void {}

  closeDialog(): void {
    this.dialog.closeAll();
  }
}

export interface RiskLevel {
  label: string;
  expectation: string;
}

export interface InputData {
  isHaveUnknown: boolean;
}

const ELEMENT_DATA: RiskLevel[] = [
  {
    label: "<img src='../assets/pcdss/manual/risk-color-green.png'/>",
    // expectation: "표준 처방",
    expectation: marker("RISK_LEVEL_EXPLAIN__GOOD"),
  },
  {
    label: "<img src='../assets/pcdss/manual/risk-color-yellow.png'/>",
    // expectation: "용법/용량 유의",
    expectation: marker("RISK_LEVEL_EXPLAIN__CAUTION"),
  },
  {
    label: "<img src='../assets/pcdss/manual/risk-color-orange.png'/>",
    // expectation: "약물반응 변화, 대체 약물/용법 유의",
    expectation: marker("RISK_LEVEL_EXPLAIN__WARNING"),
  },
  {
    label: "<img src='../assets/pcdss/manual/risk-color-red.png'/>",
    // expectation: "부작용 발생 위험, 대체 약물/용법 유의",
    expectation: marker("RISK_LEVEL_EXPLAIN__DANGER"),
  },
];
