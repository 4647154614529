import { Component, OnInit } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";

@Component({
  selector: "app-welcome-popup",
  templateUrl: "./welcome-popup.component.html",
  styleUrls: ["./welcome-popup.component.css"],
})
export class WelcomePopupComponent implements OnInit {
  constructor(private dialog: MatDialog) {}

  ngOnInit(): void {}

  closeDialog(): void {
    this.dialog.closeAll();
  }
}
