<div class="container">
  <mat-nav-list>
    <a
      target="_self"
      mat-list-item
      [routerLink]="['/intro']"
      routerLinkActive="router-link-active"
    >
      <div class="menu">
        <!-- <span translate>APP_LEFT_MENU__PGX_TESTING_INTRODUCTION</span> -->
        <span>{{ "APP_LEFT_MENU__PGX_TESTING_INTRODUCTION" | translate }}</span>
      </div>
    </a>
    <a mat-list-item [routerLink]="['/hospital/search']">
      <div class="menu">
        <!-- <span translate>APP_LEFT_MENU__AFFILIATED_HOSPITAL</span> -->
        <span>{{ "APP_LEFT_MENU__AFFILIATED_HOSPITAL" | translate }}</span>
      </div>
    </a>
    <a mat-list-item (click)="navigateReport()">
      <div class="menu">
        <!-- <span translate>APP_LEFT_MENU__MY_PGX_TESTING_RESULT</span> -->
        <span>{{ "APP_LEFT_MENU__MY_PGX_TESTING_RESULT" | translate }}</span>
      </div>
    </a>
    <a mat-list-item (click)="navigateGene()">
      <div class="menu">
        <!-- <span translate>APP_LEFT_MENU__MY_GENETIC_INFORMATION</span> -->
        <span>{{ "APP_LEFT_MENU__MY_GENETIC_INFORMATION" | translate }}</span>
      </div>
    </a>
    <!-- <a target="_self" mat-list-item [routerLink]="['/pcdss']" routerLinkActive="router-link-active"><span class="menu-font">PDSS 소개</span></a> -->
    <a mat-list-item [routerLink]="['/drug/search']">
      <div class="menu">
        <!-- <span translate>APP_LEFT_MENU__SEARCH_FOR_MEDICINE</span> -->
        <span>{{ "APP_LEFT_MENU__SEARCH_FOR_MEDICINE" | translate }}</span>
      </div>
    </a>
  </mat-nav-list>
</div>
