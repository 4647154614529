export class User {
  id: number;
  username: string;
  password: string;
  surname: string;
  givenName: string;
  fullName: string;
  gender: string;
  yearOfBirth: string;
  monthOfBirth: string;
  dayOfBirth: string;
  birthday: string;
  mobile: string;
  email: string;
  country: string;
  city: string;
  district: string;
  addressDetails: string;
  avatar: string;
  weight: number;
  height: number;
  userTypeId: number;
  // rh: string;
  // bloodType: string;
  createdTime: string;
  createdActor: string;

  displayName: string;
  displayBirthday: string;
}

export class UserResolved {
  user: User;
  error?: any;
}
