export enum RiskLevelEnum {
  UNKNOWN = "unknown",
  GOOD = "good",
  CAUTION = "caution",
  WARNING = "warning",
  DANGER = "danger",
}
export enum RiskLevelEnumCapitalized {
  UNKNOWN = "Unknown",
  GOOD = "Good",
  CAUTION = "Caution",
  WARNING = "Warning",
  DANGER = "Danger",
}

export enum RiskLevelEnumKr {
  UNKNOWN = "미정",
  GOOD = "양호",
  CAUTION = "주의",
  WARNING = "경고",
  DANGER = "위험",
}
