import { MediaMatcher } from "@angular/cdk/layout";
import {
  ChangeDetectorRef,
  Component,
  ViewChild,
  AfterViewInit,
  OnInit,
  OnDestroy,
} from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { MatSidenav, MatSidenavContainer } from "@angular/material/sidenav";
import { Subscription } from "rxjs";

import { User } from "../../../user/user";
import { AuthenticationService } from "../../services/authentication.service";
import { TokenStorageService } from "../../services/token-storage.service";

@Component({
  selector: "app-left-menu",
  templateUrl: "./left-menu.component.html",
  styleUrls: ["./left-menu.component.css"],
})
export class LeftMenuComponent implements OnInit, OnDestroy {
  authUser: User;
  authUserId: number = 0;

  authUserGetSub$: Subscription;
  authUserSignOutSub$: Subscription;

  constructor(
    changeDetectorRef: ChangeDetectorRef,
    media: MediaMatcher,
    private authenticationService: AuthenticationService,
    private tokenStorageService: TokenStorageService,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.authUserGetSub$ =
      this.authenticationService.authUserObservable$.subscribe((next: User) => {
        if (!!next && !!next.id) {
          this.authUser = next;
          this.authUserId = next.id;

          // console.log('[LeftMenuComponent.ngOnInit()] signedInUser=' + JSON.stringify(next));
        } else {
          this.authUser = null;
          this.authUserId = 0;
        }
      });

    this.authUserId = this.tokenStorageService.getAuthUserId();
  }

  ngOnDestroy(): void {
    if (!!this.authUserGetSub$) {
      this.authUserGetSub$.unsubscribe();
    }
  }

  navigateUser(): void {
    this.router.navigate(["user", this.authUserId, "detail"]);
  }

  navigateReport(): void {
    // console.log('[LeftMenuComponent.navigateReport()] authUserId=' + this.authUserId);
    if (this.authUserId > 0) {
      this.router.navigate(["user", this.authUserId, "report"]);
    } else {
      if (
        !!this.tokenStorageService.getQrCode() &&
        this.tokenStorageService.getQrCode().length > 0
      ) {
        this.router.navigate([
          "report",
          this.tokenStorageService.getQrCode(),
          "detail",
        ]);
      } else {
        this.router.navigate(["user", 0, "report"], {});
      }
    }
  }

  navigateGene(): void {
    // console.log('[LeftMenuComponent.navigateGene()] authUserId=' + this.authUserId);
    if (this.authUserId > 0) {
      this.router.navigate(["user", this.authUserId, "gene"]);
    } else {
      if (
        !!this.tokenStorageService.getQrCode() &&
        this.tokenStorageService.getQrCode().length > 0
      ) {
        this.router.navigate([
          "report",
          this.tokenStorageService.getQrCode(),
          "gene",
        ]);
      } else {
        this.router.navigate(["user", 0, "gene"], {});
      }
    }
  }
}
