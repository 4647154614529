import { NgModule } from "@angular/core";
import {
  Routes,
  RouterModule,
  PreloadAllModules,
  Router,
} from "@angular/router";

import { ApplicationStateService } from "./shared/services/application-state.service";

const routes: Routes = [
  {
    path: "home",
    loadChildren: () => import("./home/home.module").then((m) => m.HomeModule),
  },
  {
    path: "more-info",
    loadChildren: () =>
      import("./more-info/more-info.module").then((m) => m.MoreInfoModule),
  },
  {
    path: "intro",
    loadChildren: () =>
      import("./intro/intro.module").then((m) => m.IntroModule),
  },
  {
    path: "pcdss",
    loadChildren: () => import("./pdss/pdss.module").then((m) => m.PdssModule),
  },

  {
    path: "hospital",
    loadChildren: () =>
      import("./hospital/hospital.module").then((m) => m.HospitalModule),
  },

  {
    path: "drug",
    loadChildren: () => import("./drug/drug.module").then((m) => m.DrugModule),
  },

  {
    path: "report",
    loadChildren: () =>
      import("./report/report.module").then((m) => m.ReportModule),
  },
  {
    path: "user",
    loadChildren: () => import("./user/user.module").then((m) => m.UserModule),
  },
  { path: "", redirectTo: "pcdss", pathMatch: "full" },
  {
    path: "login",
    loadChildren: () =>
      import("./login/login.module").then((m) => m.LoginModule),
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {
  public constructor(
    private router: Router,
    private applicationStateService: ApplicationStateService
  ) {
    if (applicationStateService.getIsMobileResolution()) {
    }
  }
}
