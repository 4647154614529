import { Component, Input, OnInit } from "@angular/core";
import { DrugBrand } from "../../../drug/drug-search/drug-brand";

@Component({
  selector: "app-drug-brand-part",
  templateUrl: "./drug-brand-part.component.html",
  styleUrls: ["./drug-brand-part.component.css"],
})
export class DrugBrandPartComponent implements OnInit {
  @Input() subMenu: number;
  @Input() drugBrand: DrugBrand;

  dur: string;
  eeDocData: string;
  udDocData: string;
  nbDocData: string;
  brandInformation: string;

  constructor() {}

  ngOnInit(): void {}
}
