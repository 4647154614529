<!-- * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * -->
<!-- * * * * * * * * * * *trinh.dung@spmed.kr * * * * * * * * * *  -->
<!-- * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * -->

<div class="app-container" [class.app-is-mobile]="mobileQuery.matches">
  <!-- Toolbar -->
  <mat-toolbar color="primary" id="toolbar-font">
    <mat-toolbar-row>
      <button
        mat-icon-button
        class="example-icon spmed"
        aria-label="Menu icon"
        (click)="sidenav.toggle()"
      >
        <mat-icon>menu</mat-icon>
      </button>
      <div class="logo-container">
        <img src="../assets/pcdss/images/pdss-logo.svg" class="logo" />
      </div>

      <span class="toolbar-spacer"></span>

      <!-- language select language: {code: "", label: ""} -->
      <button
        [matMenuTriggerFor]="languageMenu"
        mat-icon-button
        class="example-icon favorite-icon spmed"
      >
        <mat-icon>language</mat-icon>
      </button>

      <button
        mat-icon-button
        class="example-icon favorite-icon spmed"
        aria-label="Login icon"
        (click)="navigateQrCodeScanner()"
      >
        <mat-icon>qr_code_scanner</mat-icon>
      </button>
      <!-- <button
        mat-icon-button
        class="example-icon favorite-icon spmed"
        aria-label="Login icon"
        (click)="navigateSignInOut()"
      >
        <mat-icon>{{ authUserId > 0 ? "logout" : "login" }}</mat-icon>
      </button> -->
      <!-- <a aria-label="SPMED PCDSS" target="_self" rel="noopener" href="http://localhost:61105" title="PCDSS">
                <img height="60" alt="SPMED PDCSS Logo" src="../assets/pcdss/spmed_logo.jpg" />
            </a> -->
    </mat-toolbar-row>
  </mat-toolbar>

  <!-- Left Menu + Right Content -->
  <mat-sidenav-container
    class="app-sidenav-container"
    [hasBackdrop]="mobileQuery.matches ? true : false"
    (backdropClick)="close()"
    [style.marginTop.px]="mobileQuery.matches ? 0 : 0"
  >
    <mat-sidenav
      #sidenav
      [mode]="mobileQuery.matches ? 'over' : 'side'"
      [fixedInViewport]="mobileQuery.matches"
      fixedTopGap="0"
      (click)="sidenav.close()"
      (keydown.escape)="close()"
      disableClose
    >
      <!-- Sub Toolbar: avatar + name -->
      <!-- <mat-toolbar class="avatar">
                <img height="60" alt="My avatar" src="https://ssl.pstatic.net/static/pwe/address/img_profile.png" />
                <span class="toolbar-spacer"></span>
                <span> -->
      <!-- {{ !!authUser ? authUser.username : ""}} -->
      <!-- </span>
                <button mat-icon-button class="example-icon favorite-icon" aria-label="Close icon" (click)="sidenav.toggle()">
                    <mat-icon>close</mat-icon>
                </button>
            </mat-toolbar> -->
      <div class="space"></div>
      <!-- Menu List -->
      <app-left-menu></app-left-menu>
      <!-- <mat-nav-list> -->
      <!-- Person Menu -->
      <!-- <h3 matSubheader>Personal Information</h3>
                <a mat-list-item (click)="navigateUser()">My information</a>
                <a mat-list-item (click)="navigateReport()">My report</a>
                <a mat-list-item (click)="navigateGene()">My gene</a> -->
      <!-- </mat-nav-list> -->
    </mat-sidenav>

    <mat-sidenav-content>
      <router-outlet></router-outlet>
    </mat-sidenav-content>
  </mat-sidenav-container>
</div>

<mat-menu #languageMenu="matMenu">
  <button
    mat-menu-item
    [class]="selectedLanguage.code === language.code ? 'languageSelected' : ''"
    *ngFor="let language of languages"
    (click)="changeLanguage(language)"
  >
    {{ language.label }}
  </button>
</mat-menu>
