<div [ngSwitch]="subMenu" class="drug-brand-contents" *ngIf="!!subMenu">
  <div *ngSwitchCase="'1'">
    <div *ngFor="let gene of drugBrand.genes.split(','); index as i">
      {{ i + 1 }}. <a routerLink="/gene/{{ gene }}/detail">{{ gene }}</a>
    </div>
  </div>
  <div *ngSwitchCase="'2'" class="formatted">
    {{ drugBrand.brandInformation }}
  </div>
  <div *ngSwitchCase="'3'" class="formatted">&nbsp;</div>
  <div *ngSwitchCase="'4'" class="formatted">&nbsp;</div>
  <div *ngSwitchCase="'5'" class="formatted">&nbsp;</div>
  <div *ngSwitchCase="'6'" class="formatted">&nbsp;</div>
  <div *ngSwitchCase="'7'" class="formatted">
    {{ drugBrand.dur }}
  </div>
</div>
