<mat-button class="close" mat-button mat-dialog-title (click)="closeDialog()">
  <mat-icon>close</mat-icon>
</mat-button>
<br />

<!-- <p id="welcome">Welcome to PCDSS</p> -->
<p id="welcome">{{ "WELCOME_POPUP__WELCOME_TO_PCDSS" | translate }}</p>
<p>
  <span class="blue-number-font">[1]</span>
  <!-- 왼쪽 상단의
  <img src="../../../assets/pcdss/manual/left-menu-icon.png" /> 버튼을
  클릭하셔서 다양한 기능을 이용해보세요. -->
  <span
    [innerHTML]="'WELCOME_POPUP__LEFT_MENU_GUIDE' | translate | safeHtml"
  ></span>
</p>
<p>
  <span class="blue-number-font">[2]</span>
  <!-- 모바일 환경에 맞추어진
  홈페이지입니다. -->
  <span>
    {{ "WELCOME_POPUP__MOBILE_GUIDE" | translate }}
  </span>
</p>
<p>
  <span class="blue-number-font">[3]</span>
  <!-- 내용은 계속 업데이트 됩니다. -->
  <span>
    {{ "WELCOME_POPUP__CONTENTS_UPDATE_GUIDE" | translate }}
  </span>
</p>
