<mat-form-field appearance="outline" id="risk-level-form-field">
  <mat-label>{{ "COLOR_SELECT_BOX__RISK_LEVEL" | translate }}</mat-label>
  <mat-select
    name="selectRisk"
    [placeholder]="'COLOR_SELECT_BOX__PLACEHOLDER' | translate"
    [(value)]="this.selected"
    (selectionChange)="addNewItem($event)"
  >
    <mat-option [value]="0">
      {{ "COLOR_SELECT_BOX__ALL" | translate }}
    </mat-option>
    <mat-option
      *ngFor="let item of selectRisk"
      [value]="item.value"
      [innerHtml]="item.label + item.expectation"
    >
    </mat-option>
  </mat-select>
</mat-form-field>
