import { Statistics } from "./../../../report/report-detail/report-detail.component";
import { Component, OnInit, Output, EventEmitter, Input } from "@angular/core";
import {
  RiskLevelEnumKr,
  RiskLevelEnumCapitalized,
} from "../risk-level-icon/risk-level-enum";

export interface RiskLevel {
  label: string;
  expectation: string;
}

@Component({
  selector: "app-color-select-box",
  templateUrl: "./color-select-box.component.html",
  styleUrls: ["./color-select-box.component.css"],
})
export class ColorSelectBoxComponent implements OnInit {
  @Output() selectionChange = new EventEmitter<string>();
  @Input() reportStatistics: Statistics;
  selected: number = 0;
  selectRisk: (RiskLevel & {
    value: number;
  })[] = [
    {
      value: 1,
      label:
        "<img src='../assets/pcdss/manual/risk-color-green.png'/>&nbsp;&nbsp;",
      expectation: `${RiskLevelEnumKr.GOOD} (${RiskLevelEnumCapitalized.GOOD})`,
    },
    {
      value: 2,
      label:
        "<img src='../assets/pcdss/manual/risk-color-yellow.png'/>&nbsp;&nbsp;",
      expectation: `${RiskLevelEnumKr.CAUTION} (${RiskLevelEnumCapitalized.CAUTION})`,
    },
    {
      value: 3,
      label:
        "<img src='../assets/pcdss/manual/risk-color-orange.png'/>&nbsp;&nbsp;",
      expectation: `${RiskLevelEnumKr.WARNING} (${RiskLevelEnumCapitalized.WARNING})`,
    },
    {
      value: 4,
      label:
        "<img src='../assets/pcdss/manual/risk-color-red.png'/>&nbsp;&nbsp;",
      expectation: `${RiskLevelEnumKr.DANGER} (${RiskLevelEnumCapitalized.DANGER})`,
    },
    {
      value: 5,
      label:
        "<img src='../assets/pcdss/manual/risk-color-gray.png'/>&nbsp;&nbsp;",
      expectation: `${RiskLevelEnumKr.UNKNOWN} (${RiskLevelEnumCapitalized.UNKNOWN})`,
    },
  ];

  constructor() {}

  ngOnInit(): void {}

  addNewItem($event): void {
    this.selectionChange.emit($event.value);
  }
}
