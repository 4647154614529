import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { SafeHtmlPipe } from "./pipes/safe-html.pipe";
import { ColorSelectBoxComponent } from "./components/color-select-box/color-select-box.component";
import { DrugBrandPartComponent } from "./components/drug-brand-part/drug-brand-part.component";
import { DrugRecommendationComponent } from "./components/drug-recommendation/drug-recommendation.component";
import { DrugRecommendationGeneComponent } from "./components/drug-recommendation-gene/drug-recommendation-gene.component";
import { ExpansionPanelReportComponent } from "./components/expansion-panel-report/expansion-panel-report.component";
import { LeftMenuComponent } from "./components/left-menu/left-menu.component";
import { ReportGeneInfoComponent } from "./components/report-gene-info/report-gene-info.component";
import { RiskLevelDisplayComponent } from "./components/risk-level-display/risk-level-display.component";
import { RiskLevelIconComponent } from "./components/risk-level-icon/risk-level-icon.component";
import { RiskPercentageComponent } from "./components/risk-percentage/risk-percentage.component";
import { FormsModule } from "@angular/forms";
import { HttpClientModule } from "@angular/common/http";
import { AppMaterialModule } from "../app-material.module";
import { RouterModule } from "@angular/router";
import { TranslateModule } from "@ngx-translate/core";
import { WelcomePopupComponent } from "./components/welcome-popup/welcome-popup.component";
import { RiskLevelExplainComponent } from "./components/risk-level-explain/risk-level-explain.component";
import { SelectLangDirective } from "./directives/select-lang.directive";
import { SelectLangComponent } from "./components/select-lang/select-lang.component";

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    RouterModule,
    // BrowserAnimationsModule,
    HttpClientModule,
    AppMaterialModule,
    TranslateModule.forChild(),
  ],
  declarations: [
    // PIPES
    SafeHtmlPipe,
    // DIRECTIVES
    SelectLangDirective,
    // COMPONENTS
    ColorSelectBoxComponent,
    DrugBrandPartComponent,
    DrugRecommendationComponent,
    DrugRecommendationGeneComponent,
    ExpansionPanelReportComponent,
    LeftMenuComponent,
    ReportGeneInfoComponent,
    RiskLevelDisplayComponent,
    RiskLevelIconComponent,
    RiskPercentageComponent,
    WelcomePopupComponent,
    RiskLevelExplainComponent,
    SelectLangComponent,
  ],
  exports: [
    // PIPES
    SafeHtmlPipe,

    // DIRECTIVES
    SelectLangDirective,

    // COMPONENTS
    ColorSelectBoxComponent,
    DrugBrandPartComponent,
    DrugRecommendationComponent,
    DrugRecommendationGeneComponent,
    ExpansionPanelReportComponent,
    LeftMenuComponent,
    ReportGeneInfoComponent,
    RiskLevelDisplayComponent,
    RiskLevelIconComponent,
    RiskPercentageComponent,
    WelcomePopupComponent,
    RiskLevelExplainComponent,
    SelectLangComponent,
  ],
})
export class SharedModule {}
