import { Inject, Injectable, InjectionToken } from "@angular/core";
import {
  HttpInterceptor,
  HttpRequest,
  HttpHandler,
  HttpEvent,
} from "@angular/common/http";

import { Observable } from "rxjs";
import { timeout } from "rxjs/operators";

export const DEFAULT_TIMEOUT = new InjectionToken<number>("defaultTimeout");
export const defaultTimeout = 60000;

@Injectable({
  providedIn: "root",
})
export class TimeoutInterceptor implements HttpInterceptor {
  constructor(@Inject(DEFAULT_TIMEOUT) protected defaultTimeout1) {}

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    const timeoutInMilliseconds =
      Number(req.headers.get("timeout")) || this.defaultTimeout1;
    return next.handle(req).pipe(timeout(timeoutInMilliseconds));
  }
}
