import { Component, Input, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { Variant } from "../../../report/gene/variant";

@Component({
  selector: "app-report-gene-info",
  templateUrl: "./report-gene-info.component.html",
  styleUrls: ["./report-gene-info.component.css"],
})
export class ReportGeneInfoComponent implements OnInit {
  @Input() variant: Variant;

  genotypeDetail: string;
  genotypePercentage: number;
  genotypeFrequencyStudy: string;
  phenotypeDetail: string;
  phenotypeAnnotation: string;
  qrCode = "";

  constructor(private route: ActivatedRoute) {}

  ngOnInit(): void {
    const rsid = this.variant.variantRsid;
    const genotypeCode = !!this.variant.variantGenotypeCode
      ? this.variant.variantGenotypeCode
      : this.variant.variantGenotype;
    const phenotype = !!this.variant.variantPhenotype
      ? this.variant.variantPhenotype
      : this.variant.variantPhenotypeCode;
    const frequency = this.variant.frequency;
    const frequencyStudy = this.variant.frequencyStudy;

    // genotype
    if (!!rsid) {
      this.genotypeDetail = ` ${rsid} ${genotypeCode}`;
    } else {
      this.genotypeDetail = ` ${genotypeCode}`;
    }

    if (!!frequency && !!frequencyStudy) {
      const percentage = parseFloat((frequency * 100).toFixed(2));
      this.genotypePercentage = percentage;
      this.genotypeFrequencyStudy = frequencyStudy;
    }

    // phenotype
    this.phenotypeDetail = "";
    if (!!phenotype) {
      this.phenotypeDetail = `${phenotype}`;
      if (this.variant.userGeneSymbol.startsWith("HLA")) {
        this.phenotypeDetail = `${rsid} ${phenotype}`;
      }
    }

    this.qrCode = this.route.snapshot.paramMap.get("qr_code");
  }
}
