import { Component, Input, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";

import { DrugRecommendationGene } from "../../../report/drug-recommendation-gene";
import { TranslateService } from "@ngx-translate/core";

@Component({
  selector: "app-drug-recommendation-gene",
  templateUrl: "./drug-recommendation-gene.component.html",
  styleUrls: ["./drug-recommendation-gene.component.css"],
})
export class DrugRecommendationGeneComponent implements OnInit {
  @Input() gene: DrugRecommendationGene;
  @Input() totalInterpretationDisplay: boolean;

  phenotypeDetail: string;
  qrCode: string = "";

  constructor(
    private route: ActivatedRoute,
    private translateService: TranslateService
  ) {}

  ngOnInit(): void {
    const rsid = this.gene.rsid;
    const genotypeCode = this.gene.genotypeCode;
    const genotype = this.gene.genotype;
    const phenotype = this.gene.phenotype;

    this.phenotypeDetail = "";
    this.qrCode = this.route.snapshot.paramMap.get("qr_code");

    if (!!rsid) {
      if (this.gene.symbol.startsWith("HLA") && genotype === "Other/Other") {
        this.phenotypeDetail = this.translateService.instant(
          "DRUG_RECOMMENDATION_GENE__NEGATIVE",
          { rsid }
        );
      } else if (
        this.gene.symbol.startsWith("HLA") &&
        genotype !== "Other/Other"
      ) {
        this.phenotypeDetail = this.translateService.instant(
          "DRUG_RECOMMENDATION_GENE__POSITIVE",
          { rsid }
        );
      } else if (this.gene.symbol === "CYP2A6") {
        this.phenotypeDetail = `${genotypeCode}`;
      } else if (this.gene.symbol === "CYP19A1") {
        this.phenotypeDetail = `${genotypeCode}`;
      } else if (this.gene.symbol === "CYP3A4") {
        this.phenotypeDetail = `${genotypeCode}`;
      } else if (this.gene.symbol === "UGT1A4") {
        this.phenotypeDetail = `${genotypeCode}`;
      } else if (this.gene.symbol === "CYP1A2") {
        this.phenotypeDetail = `${genotypeCode}`;
      } else if (this.gene.symbol === "UGT2B15") {
        this.phenotypeDetail = `${genotypeCode}`;
      } else {
        this.phenotypeDetail = `${rsid} ${genotypeCode}`;
      }
    } else {
      if (this.gene.symbol === "CYP19A1") {
        this.phenotypeDetail = `${genotypeCode}`;
      } else if (this.gene.symbol === "CYP3A4") {
        this.phenotypeDetail = `${genotypeCode}`;
      } else if (this.gene.symbol === "UGT1A4") {
        this.phenotypeDetail = `${genotypeCode}`;
      } else if (this.gene.symbol === "CYP1A2") {
        this.phenotypeDetail = `${genotypeCode}`;
      } else if (this.gene.symbol === "UGT2B15") {
        this.phenotypeDetail = `${genotypeCode}`;
      } else {
        this.phenotypeDetail = `${rsid} ${genotypeCode}`;
      }
    }
  }
}
