import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { from, Observable, throwError } from "rxjs";
import { tap } from "rxjs/operators";

import { environment } from "../../environments/environment";

import { UserEditRequest } from "./user-edit/user-edit-request";
import { SpmedResponse } from "../shared/services/response/spmed-response";

@Injectable({
  providedIn: "root",
})
export class UserDataService {
  private lang: string = "en";
  private baseUrl = `${environment.gateway}/pcdss/v${environment.version}/${this.lang}/users`;

  constructor(private http: HttpClient) {}

  /**
   * Get user information by id
   */
  getUserById(id: number): Observable<SpmedResponse> {
    const url = `${this.baseUrl}/${id}`;

    return this.http
      .get<SpmedResponse>(url)
      .pipe
      // tap(data => console.log(JSON.stringify(data)))
      ();
  }

  /**
   * Update information
   */
  update(id: number, payload: UserEditRequest): Observable<SpmedResponse> {
    const url = `${this.baseUrl}/${id}`;
    const headers = new HttpHeaders({ "Content-Type": "application/json" });

    // Calling API for updating user information
    return this.http
      .put<SpmedResponse>(url, payload, { headers })
      .pipe
      // tap(data => console.log(JSON.stringify(data)))
      ();
  }

  /**
   * Get all genes by id
   */
  getAllVariantById(id: number): Observable<SpmedResponse> {
    const url = `${this.baseUrl}/${id}/variants`;

    return this.http
      .get<SpmedResponse>(url)
      .pipe
      // tap(data => console.log(JSON.stringify(data)))
      ();
  }

  /**
   * Get all report by id
   */
  getAllReportById(id: number): Observable<SpmedResponse> {
    const url = `${this.baseUrl}/${id}/reports`;

    return this.http
      .get<SpmedResponse>(url)
      .pipe
      // tap(data => console.log(JSON.stringify(data)))
      ();
  }
}
