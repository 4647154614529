<mat-expansion-panel expanded>
  <!-- Header -->
  <!--mat-expansion-panel-header>
        <mat-panel-title>
            {{ report.qrCode }}
        </mat-panel-title>
    </!--mat-expansion-panel-header>

        Content: Recommendations -->
  <p *ngFor="let drugRecommendation of report.drugRecommendations">
    <app-drug-recommendation [drugRecommendation]="drugRecommendation">
    </app-drug-recommendation>
  </p>
</mat-expansion-panel>
