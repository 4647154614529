import { Component, Input, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";

import { DrugRecommendation } from "../../../report/drug-recommendation";

@Component({
  selector: "app-drug-recommendation",
  templateUrl: "./drug-recommendation.component.html",
  styleUrls: ["./drug-recommendation.component.css"],
})
export class DrugRecommendationComponent implements OnInit {
  @Input() drugRecommendation: DrugRecommendation;
  qrCode = "";

  constructor(private route: ActivatedRoute) {}

  ngOnInit(): void {
    this.qrCode = this.route.snapshot.paramMap.get("qr_code");
  }
}
