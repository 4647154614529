import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Subject, BehaviorSubject, Observable } from "rxjs";
import { tap } from "rxjs/operators";
import { environment } from "src/environments/environment";

import { User } from "../../user/user";
import { UserSigninRequest } from "../../user/user-signin/user-signin-request";
import { UserSignup } from "../../user/user-singup/user-signup";
import { SpmedResponse } from "./response/spmed-response";

import { TokenStorageService } from "./token-storage.service";

const httpOptions = {
  headers: new HttpHeaders({ "Content-Type": "application/json" }),
};

@Injectable({
  providedIn: "root",
})
export class AuthenticationService {
  private lang: string = "en";
  private baseUrl = `${environment.gateway}/pcdss/v${environment.version}/${this.lang}/users`;
  private _initUser: User = new User();
  private _authUserSubject = new BehaviorSubject<User | null>(null);

  authUserObservable$ = this._authUserSubject.asObservable();

  constructor(
    private http: HttpClient,
    private tokenStorageService: TokenStorageService
  ) {}

  // get authUserId(): number {
  //   return this._authUserId;
  // }
  // set authUserId(value: number) {
  //   this._authUserId = value;
  // }

  setAuthUser(user: User): void {
    this._authUserSubject.next(user);
    if (!!user) {
      // sign-in
      this.tokenStorageService.saveAuthUserId(user.id);
    } else {
      // sign-out
      this.tokenStorageService.removeAuthUserId();
    }
  }

  signIn(payload: UserSigninRequest): Observable<SpmedResponse> {
    const url = `${this.baseUrl}/signin`;
    return this.http
      .post<SpmedResponse>(url, payload, httpOptions)
      .pipe
      // tap(data => console.log(JSON.stringify(data)))
      ();
  }

  signOut(): Observable<SpmedResponse> {
    const url = `${this.baseUrl}/signout`;
    return this.http
      .post<SpmedResponse>(url, httpOptions)
      .pipe
      // tap(data => console.log(JSON.stringify(data)))
      ();
  }

  register(payload: UserSignup): Observable<SpmedResponse> {
    const url = `${this.baseUrl}/signup`;

    return this.http
      .post<SpmedResponse>(url, payload, httpOptions)
      .pipe
      // tap(data => console.log(JSON.stringify(data)))
      ();
  }
}
