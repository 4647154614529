import { Component, OnInit, Input, OnChanges } from "@angular/core";
import { RiskLevelEnum } from "../risk-level-icon/risk-level-enum";
import { marker } from "@biesbjerg/ngx-translate-extract-marker";

@Component({
  selector: "app-risk-percentage",
  templateUrl: "./risk-percentage.component.html",
  styleUrls: ["./risk-percentage.component.css"],
})
export class RiskPercentageComponent implements OnInit, OnChanges {
  @Input() risk: string;
  @Input() percentage: string;

  riskTitle: string;
  cssClass: string;
  cssClassPercent: string;

  constructor() {}

  ngOnInit(): void {}

  ngOnChanges(): void {
    if (this.risk === RiskLevelEnum.GOOD) {
      this.cssClass = RiskLevelEnum.GOOD;
      this.cssClassPercent = "green-percent";
      this.riskTitle = marker("RISK_LEVEL__GOOD__TITLE");
    } else if (this.risk === RiskLevelEnum.CAUTION) {
      this.cssClass = RiskLevelEnum.CAUTION;
      this.cssClassPercent = "yellow-percent";
      this.riskTitle = marker("RISK_LEVEL__CAUTION__TITLE");
    } else if (this.risk === RiskLevelEnum.WARNING) {
      this.cssClass = RiskLevelEnum.WARNING;
      this.cssClassPercent = "orange-percent";
      this.riskTitle = marker("RISK_LEVEL__WARNING__TITLE");
    } else if (this.risk === RiskLevelEnum.DANGER) {
      this.cssClass = RiskLevelEnum.DANGER;
      this.cssClassPercent = "red-percent";
      this.riskTitle = marker("RISK_LEVEL__DANGER__TITLE");
    } else if (this.risk === RiskLevelEnum.UNKNOWN) {
      this.cssClass = RiskLevelEnum.UNKNOWN;
      this.cssClassPercent = "gray-percent";
      this.riskTitle = marker("RISK_LEVEL__UNKNOWN__TITLE");
    }
  }
}
