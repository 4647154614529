<mat-button class="close" mat-button mat-dialog-title (click)="closeDialog()"
  >X</mat-button
><br />
<!--div><img src="../assets/pcdss/manual/2021-04-07_Risk_Level_Explanation.png"></div-->

<table mat-table [dataSource]="dataSource" class="mat-elevation-z8">
  <!-- Label Column -->
  <ng-container matColumnDef="label">
    <!-- <th mat-header-cell *matHeaderCellDef>Label</th> -->
    <th mat-header-cell *matHeaderCellDef>
      {{ "RISK_LEVEL_EXPLAIN__LABEL" | translate }}
    </th>
    <td
      mat-cell
      *matCellDef="let color"
      id="color-label"
      [innerHtml]="color.label"
    >
      {{ color.label }}
    </td>
  </ng-container>

  <!-- Interpretation Column -->
  <ng-container matColumnDef="expectation">
    <!-- <th mat-header-cell *matHeaderCellDef>Interpretation</th> -->
    <th mat-header-cell *matHeaderCellDef>
      {{ "RISK_LEVEL_EXPLAIN__INTERPRETATION" | translate }}
    </th>
    <td
      mat-cell
      *matCellDef="let color"
      id="color-expectation"
      [innerHtml]="color.expectation | translate"
    >
      {{ color.expectation | translate }}
    </td>
  </ng-container>

  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
  <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
</table>
