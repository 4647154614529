import { Component, Input, OnInit } from "@angular/core";

import { Report } from "../../../report/report";

@Component({
  selector: "app-expansion-panel-report",
  templateUrl: "./expansion-panel-report.component.html",
  styleUrls: ["./expansion-panel-report.component.css"],
})
export class ExpansionPanelReportComponent implements OnInit {
  @Input() report: Report;

  constructor() {}

  ngOnInit(): void {}
}
