import { Injectable } from "@angular/core";
import { User } from "../../user/user";
import { BehaviorSubject } from "rxjs";

const TOKEN_KEY = "token_key";
const REFRESH_TOKEN_KEY = "refresh_token_key";
const USER_DETAIL_KEY = "user_detail_key";
const ORIGIN_KEY = "origin_key";
const QR_CODE_KEY = "qr_code_key";
const AUTH_USER_ID_KEY = "auth_user_id_key";
@Injectable({
  providedIn: "root",
})
export class TokenStorageService {
  constructor() {}

  signOut(): void {
    window.sessionStorage.clear();
  }

  /**
   * Token
   */
  public saveToken(token: string, refreshToken: string): void {
    window.sessionStorage.removeItem(TOKEN_KEY);
    window.sessionStorage.removeItem(REFRESH_TOKEN_KEY);
    window.sessionStorage.setItem(TOKEN_KEY, token);
    window.sessionStorage.setItem(REFRESH_TOKEN_KEY, refreshToken);
  }
  public removeToken(): void {
    window.sessionStorage.removeItem(TOKEN_KEY);
    window.sessionStorage.removeItem(REFRESH_TOKEN_KEY);
    window.sessionStorage.removeItem(ORIGIN_KEY);
    window.sessionStorage.removeItem(QR_CODE_KEY);
  }
  public getToken(): string {
    return sessionStorage.getItem(TOKEN_KEY);
  }

  /**
   * QR Code
   */
  public saveQrCode(qrCode: string): void {
    window.sessionStorage.removeItem(QR_CODE_KEY);
    window.sessionStorage.setItem(QR_CODE_KEY, qrCode);
  }
  public getQrCode(): string {
    return sessionStorage.getItem(QR_CODE_KEY);
  }

  /**
   * Origin
   */
  public saveOrigin(origin: string): void {
    window.sessionStorage.removeItem(ORIGIN_KEY);
    window.sessionStorage.setItem(ORIGIN_KEY, origin);
  }
  public getOrigin(): string {
    return sessionStorage.getItem(ORIGIN_KEY);
  }

  /**
   * User
   */
  public saveUser(user: User): void {
    window.sessionStorage.removeItem(USER_DETAIL_KEY);
    window.sessionStorage.setItem(USER_DETAIL_KEY, JSON.stringify(user));
  }
  public getUser(): User {
    return JSON.parse(sessionStorage.getItem(USER_DETAIL_KEY));
  }

  /**
   * Auth User ID
   */
  public saveAuthUserId(authUserId: number): void {
    window.sessionStorage.removeItem(AUTH_USER_ID_KEY);
    window.sessionStorage.setItem(AUTH_USER_ID_KEY, authUserId.toString());
  }
  public getAuthUserId(): number {
    return +sessionStorage.getItem(AUTH_USER_ID_KEY);
  }
  public removeAuthUserId(): void {
    window.sessionStorage.removeItem(AUTH_USER_ID_KEY);
  }
}
